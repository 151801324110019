.ws-btn {
	font-size: 1.2rem;
	// text-transform: uppercase;
	text-transform: none;
	font-weight: 500;
	padding: 0rem 1.8rem;
	line-height: 2.6rem;
	color: $light-primary;
	background-color: $dark-gray;
	border: none;
	border-radius: 5px;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	
	// &.ws-mini {
	// 	@extend .ws-mini;
	// }

	&.btn-pill {
		line-height: 3em;
		padding-top: 0;
		padding-bottom: 0;
		border-radius: 1.5em;
	}

	&.c2a-btn {
		line-height: 2.6em;
		padding: 0 3em;
		border-radius: 1.3em;
	}

	&.ws-btn-mini {
		font-size: 1rem;
		padding: .7rem 1rem;
		background-color: $green;
		position: absolute;
		right: .5rem;
		top: .68rem;
	} 
	&#submit {
		background-color: $blue;
	}

	&:hover {
		text-decoration: none;
	}

	&:focus {
		outline: none;
		box-shadow: inset 0px 0px 0px 2px rgba(#000,0.12);
	}
	
}
.ws-link {
	background-color: transparent;
	color: $light-primary;
	font-weight: 400;
	padding: 0;
	
}