
@import '../../config/variables';

.prog-photo-container {
	$pct-blur: 4%;
	$zdx: 7000; //___ z-index of header ___//
	position: relative;
	overflow: hidden;
	img {
		position: absolute;
		margin: 0;
		top: 50%;
		left: 50%;
		height: 100%;
		width: initial;
		min-width: 100%+$pct-blur;
		min-height: 100%+$pct-blur;
		// margin-left: -0.5*$pct-blur;
		// margin-top: -0.5*$pct-blur;
		transform: translate(-50%,-50%);
	}
	.blurholder {
		filter: blur(1rem);
		z-index: $zdx;
	}
	.full-res {
		z-index: $zdx+1;
	}
	.skirt {
		width: 100%;
		height: 33%;
		height: 20vh;
		position: absolute;
		bottom: 0;
		left: 0;
		// background-color: rgba(#000,0.5);
		z-index: $zdx+10;
		@extend %clear-to-black;
	}
}
