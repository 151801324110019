@import '../../../config/variables';
.theme-dark {
	#contact-root {

		td {
			// background-color: #fff;
			color: $fg-light-primary;
		}
	}
}
#contact-root {
	
	//** VARIABLES **//
	$f-size-normal: 1.1rem;
	$f-size-tablet: 1rem;
	$f-size-mobile: .9rem;
	// $header-color: $main-color;
	// $header-color: $dark;
	$header-color: $darker;
	
	min-height: 100vh;
	width: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;

	
	main {
		min-height: 50vh; // default is min-height of 100vh, but this page will be smaller.
	}

	.section {
		flex: 1;
		padding: 3rem 0;

	}

	table {
		width: initial;
		max-width: 100%;
	}
	
	td {
		font-size: $f-size-normal;
		text-transform: uppercase;
		letter-spacing: .1em;
		color: $text-primary;
		padding: 1rem .75rem;
		line-height: 2rem;
		// font-weight: 400;
		transition: all .24s;

		@media (max-width: $break-c){ 
			font-size: $f-size-tablet; 
		}
		@media (max-width: $break-b){ 
			font-size: $f-size-mobile;
			padding-left: .5rem;
			padding-right: .5rem;
		 }
		
		//** LEFT COLUMN **//
		&:first-child {
			font-weight: 700;
		}

		a {
			// Inherit from parent (td) by default
			font-size: inherit;
			font-weight: inherit;
			color: inherit;
			letter-spacing: inherit;

			display: inline-block;
			border-bottom: 2px solid transparent;
			border-color: $dividers;
			// color: $secondary;
			font-weight: 400;
			line-height: 2;
			padding: 0 3px;
			transition: border-color .24s;

			&:hover, &:focus {
				border-color: $accent;
				text-decoration: none;
				// color: $link;
			}
		}
	}





	header {
		// min-height: 180px;
		// max-height: 240px;
		min-height: 230px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		padding: 2rem;
		color: #fff;
		// background-color: $bootflat-lynch;
		background-color: $header-color;

		.liner {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			flex-wrap: wrap;
			max-width: 800px;
			margin: 0;
			width: 90%;

			& > * {
				margin: 0 0.5rem;
				display: inline-block;
			}
		}

		.headline {
			margin: 0;
			text-align: center;
				// font-size: 1.8rem;
				// font-weight: 300;
				// letter-spacing: -0.8px;
			font-size: $font-ML;
			letter-spacing: .1em;
			text-transform: uppercase;
			font-weight: 500;
		}
		h3,
		.subheadline {
			font-weight: 300;
		}
		h1,
		h3 {
			line-height: 1.2;
		}
	}
	
	#body-section { 
		background-color: #fff;
		// color: rgba(0, 0, 0, 0.87);
		color: $fg-primary;
		font-size: 1.8rem;
		font-weight: 300;
		letter-spacing: .2px;
		word-spacing: 1.2px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		padding: 2.4rem;

		p { margin: 0; }

		& > * { flex: 1 }

		.flex-boundary {
			display: inherit;
			flex-direction: inherit;
			align-items: stretch;
			justify-content: center;
			flex-wrap: nowrap;
			max-width: 1000px;
		}
		
		.subsection {
			margin: 1rem 0;
			font-size: 1em;
			line-height: 1;
			text-align: left;
		}

		.eyebrow {
			line-height: 1;
			color: rgba(0,0,0,0.5);
			color: $fg-secondary;
			margin-bottom: 0;
				font-size: .52em;
				font-weight: 600;
				letter-spacing: .1em;	
				text-transform: uppercase;
		}

		.feature-content {
			color: #08c;
			font-size: .75em;
			font-weight: 500; //Roboto
			// font-weight: 600; //Open Sans
			text-transform: uppercase;
			letter-spacing: .1em;
			display: block;
			line-height: 1.5;
		}
	}

}