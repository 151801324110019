
#portfolio-root{
	min-height: 100vh;
	#portfolio-header {
		// background: transparent;
		min-height: 800px;
		height: 160vh;
		max-height: 2160px;
		justify-content: flex-start;
		padding: 0;
		// @extend %grad-blue-sky;
		@extend %grad-concrete;
		position: relative;

		.ws-fixed-backdrop {
			position: fixed;
			height: 100vh;
			width: 100vw;
			margin: 0;
			top: 0;
			left: 0;
		}
		.full-page-container {
			line-height: 100vh;
			height: 100vh;
			width: 100%;
			position: relative;
			margin: 0;
			padding: 0;
		}
		h1 {
			font-size: 1.6rem;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 0.18em;
			word-spacing: 0.4em;
			width: 100%;
			margin: 0;
			$h: 100vh;
			line-height: $h; // also determines height
			strong { font-weight: 600; }

			@media (max-width: 420px){
				font-size: 1.2rem;
			}
		}

		.header-content {
			display: block;
			margin: 24vh 0;
				height: 60vh;
				margin: 0;
				
			.headline, .subheadline {
				position: relative;
			}
			.subheadline {
				z-index: 7105;
			}
			.headline {
				z-index: 7101;
				display: block;
			}
		}


	}
	
	#cube-decor-1 { // Below headline
		z-index: 7102;
		position: absolute;

			top: -50px;
			left: 50%;
			height: 80px;
			margin-left: 0;
	}
	#cube-decor-2 { // Above headline
		top: -50px;
		right: 50%;
		margin-right: 30px;
		height: 90px;
		z-index: 7099;
	}
	#cube-decor-0 { // Highest
		top: -240px;
		right: 50%;
		// margin-right: 30px;
		height: 50px;
		z-index: 7099;
	}
	.row, 
	.ws-row,
	.action-bar .liner.limit-width {
		margin: 0 auto;
		max-width: $max-content-width - 100px;
		padding: 0 2.5%;
	} 

	.ws-footer {
		.ws-row {
			max-width: 100%;
		}
	}
	
} 