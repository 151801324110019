// @import "config/_config";

#error404-root {
	// padding: 5%;
	min-height: 100vh;

	.headline {
		font-size: $font-L;
		font-weight: 300;
		color: $text-primary;
		span {
			font-weight: 700;
		}
	}
	.subheadline {
		font-size: $font-ML;
		margin-top: 3rem;
		font-weight: $regular;
		color: $text-secondary;
		// max-width: 420px;
	}
	h2 {
		font-weight: $regular;
		font-size: 3rem;
	}
	.pop {
		font-weight: $heavy
	}

	#waldo-img {
		height: 200px;
		position: absolute;
		bottom: 0;
		right: 0;
	}

	.page-focus {
		width: 100%;
		padding: 0 5%;
		max-width: 700px;
		margin: auto;
		// h1 {
		// 	font-size: 2rem;
		// 	font-weight: 700;
		// }
	}
}