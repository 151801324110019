.ws-link-collection {
	list-style-type: none;
	padding: 0;
	margin: 0;
	// outline: 1px solid green;
	width: 100%;
	font-size: 1.3rem;
	background-color: #fff;
	li {
		outline: 1px solid $dividers;
		a {
			display: inline-block;
			width: 100%;
			height: 100%;
			padding: 0 1em;
			line-height: 3em;
			color: $text-secondary;
			font-weight: 400;
			&:hover {
				color: $text-primary;
			}

			&.selected {
				background-color: $bg-dark;
				background-color: $blue;
				color: $light-primary;
			}
		}
	}
}

// .link-list {
// 	ul {
// 		list-style-type: none;
// 		padding: 0;
// 		margin: 0;
// 		font-size: 80%;
// 		display: flex;
// 		align-items: center;
// 		justify-content: space-around;
// 		max-width: 500px;
// 		margin: auto;
// 		li {
// 			a {
// 				display: inline-block;
// 				padding: 0.75rem;
// 				height: 1rem;
// 				width: 1rem;
// 				opacity: 0.7;
// 				transition: 500ms;
// 				// border-radius: 50%;
// 				// border: 2px solid transparent;
// 				&:hover {
// 					opacity: 0.9;
// 					// border-color: #000;
// 				}
// 				img {
// 					display: inline-block;
// 					height: inherit;
// 					width: initial;
// 				}
// 			}
// 		}
// 	}
// }


.link-list {
	box-sizing: initial;

	&.icon-links {
		padding: 0;
		max-width: 500px;
		width: 81%;
		margin: auto;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		font-size: 80%;
		display: flex;
		align-items: center;
		justify-content: space-around;
		max-width: 500px;
		margin: auto;
		li {
			a {
				font-size: 1.8em;
				display: inline-block;
				padding: 0.75em;
				height: 1em;
				width: 1em;
				opacity: 0.7;
				transition: 500ms;
				// border-radius: 50%;
				// border: 2px solid transparent;
				&:hover {
					opacity: 0.9;
					// border-color: #000;
				}
				img {
					display: inline-block;
					height: inherit;
					width: initial;
				}
			}
		}
	}
}
