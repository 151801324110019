
.ws-article {
	padding: 6rem 3rem 3rem;
	max-width: 1000px;
	margin: auto;
	.eyebrow {
		margin: 0;
		margin-bottom: .5em;
	}
	header.hero {
		font-size: 3rem;
		padding: 0;
		margin-bottom: .5em;
		.hero-headline {
			font-size: 1em;
			line-height: 1.1;
		}
		.hero-subheadline {
			font-size: 0.55em;
			font-weight: $semibold;
			color: $text-secondary;
			letter-spacing: -0.2px;
			word-spacing: 1.2px;
		}
	}

	.chapter {
		font-size: 1rem;
		.headline {
			font-weight: $bold;
			font-size: 1em;
		}
		
	}


	// .headline {
	// 	font-size: 1em;
	// }
	// .subheadline {
	// 	font-size: 0.55em;
	// 	font-weight: $semibold;
	// 	color: $text-secondary;
	// 	letter-spacing: -0.2px;
	// 	word-spacing: 1.2px;
	// }




}