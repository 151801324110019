@import '../../config/variables';

.ws-footer {
	$gf-break: 600px;
	$gf-break-2: 450px;
	$footer-bg-color: $off-dark;
	$footer-text-color: $light-secondary;
	$footer-title-color: $light-primary;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	padding: 0;
	color: $text-primary;
	background-color: $footer-bg-color;


	@media (max-width: $gf-break-2){
		// * Switches the position of the copyright notice from below links to above links
		// flex-direction: column-reverse;
	}
	.main-section {
		padding: 2rem 0;
		justify-content: center;
		align-items: center;
		.liner {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: stretch;
			flex-wrap: wrap;
			min-width: 150px;
			margin: auto;
			@media (max-width: $gf-break){
				max-width: 400px;
			}
			
			.gf-nav {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: stretch;
				text-align: left;
				font-size: 0.8rem;
				background-color: transparent;
				margin: 1rem;
				font-weight: 400;
				min-width: 100px;
				max-width: 130px;
				
				@media (max-width: $gf-break){
					// font-size: 1.1rem;
					flex-basis: 50%;
				}
				@media (max-width: $gf-break-2){
					flex-basis: 100%;
					font-size: 1.1rem;
					max-width: 180px;
				}
				@media (max-width: $gf-break-2 - 50px){
					font-size: 1.2rem;
				}

				> * {
					padding: 0 .5em;
					line-height: 1.7;
				}
				a {
					// color: $light-primary;
					color: $footer-text-color;
					font-size: inherit;
				}
				.title {
					font-weight: 600;
					font-size: 1.1em;
					color: $footer-title-color;
					// color: $text-primary;
				}
			}
		}
	}
	.fineprint-section {
		padding: 0;
		// background-color: rgba(24,24,24,0.24);
		background-color: rgba(24,24,24,0.08);
		color: $footer-text-color;
		text-align: center;
		
		.fineprint {
			@media (max-width: $gf-break-2) {
				font-size: 0.9rem;
				font-weight: 300;
			}
		}
	}
}