@import '../../config/variables';

.photo-display-article {
	background-color: $dark;
	background-color: $darker;
	background-color: #000;
	background-color: $asphalt;
	// background-color: $wet-asphalt;
	padding-top: 5rem;
	min-height: 100vh;

	@media all and (max-width: $break-m) {
		padding-left: 0;
		padding-right: 0;
	}
}

// .photo-display-container {
// }
.photo-display {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	min-height: 90vh;
	// max-height: 100vh;
	@media all and (max-width: $break-m) {
		min-height: unset;
	}

	.bounds {
		width: 96%;
		margin: auto;
		@media all and (max-width: $break-m) {
			width: 100%;
		}
	}
}
// Image Area -----------------------------
.display-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-height: 90vh;
	background-color: #000;
	background-color: $wet-asphalt;

	flex: 9;
	max-height: initial;
}
.actual-photo {
	// flex: 1;
	width: 100%;
	height: initial;
	max-height: initial;
	// outline: 1px solid red;
}

// Info Area -------------------------------

.photo-info-container {
	padding: 1rem 0;
	margin-top: 2rem;
	// outline: 1px solid red;
	flex: 1;
	@media all and (max-width: $break-m) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
.photo-info {
	// display: flex;
	// flex-direction: row;
	// align-items: flex-start;
	// justify-content: stretch;

	> * {
		// outline: 1px solid rgba(#fff, 0.25);
		min-width: 300px;
	}

	// Headlines ..........................
	.pi-hero {
		// flex: 1;
	}
	.pi-headline {
		margin: 0;
		line-height: 1;
		margin-bottom: .25em;
	}
	.pi-subheadline {
		margin: 0;
		line-height: 1.25;
		// margin-bottom: .2em;
	}

	// Details ...........................
	.pi-details {
		// flex-basis: 33%;
		// max-width: 250px;
		font-size: .9rem;
		color: $fg-light-secondary;

		li {
			line-height: 2em;
			height: 2em;
		}

		span {
			margin-left: 1rem;
		}
	}
}
