@import '../../config/variables';


.badge {
	font-size: .9rem;
	font-weight: 400;

	// outline: 1px solid red;
	background-color: $dark;
	color: $fg-light-primary;
	line-height: 1;
	padding: .5em .75em;
	border-radius: .5em;

}