//--------------------
//    Marks 
//--------------------

mark {
	background-color: transparent;

	padding: 0.1em 0.4em 0.2em;
	border-radius: 0.4em;

	// background-color: $atom-yellow;

	&.mark-yellow {
	}
}

.mark-yellow { background-color: $accent-yellow }

$accentConfig: 
	'yellow' $accent-yellow, 
	'pink'   $accent-pink,
	'green'  $accent-green,
;

@each $i in $accentConfig {
	$key: nth($i,1);
	$color: nth($i,2);

	$is-dark:  ( lightness($color) < 5  );
	$is-light: ( lightness($color) > 80 );


	.mark-#{$key} {
		background-color: $color;

		&.mark-on-hover {
			background-color: initial;
			&:hover {
				background-color: $color;
			}
		}
	}
	
}
