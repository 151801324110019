  
*, *:before, *:after {
	box-sizing: inherit;
}

html {
	height: 100%;
}

body {
	display: flex;
	flex-direction: column;
	// justify-content: space-between;
	justify-content: stretch;
	align-items: center; 
	margin: 0;
	padding: 0;
	min-height: 100vh;
	width: 100%;
	background-color: #fff;
	height: initial; //___ important. or else it won't expand as content expands ___//
}



#root {
	width: 100%;
	height: 100%;

	// The root element on page components. Right under #root
	& > * {
		padding: 0;
		width: 100%;
		overflow: hidden;
		// FLEX
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		flex: 1;
	}
}

#outer-space {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: $zindex-outer-space;
}


.page-main {
	display: flex;
	flex-direction: column;
	// justify-content: flex-start;
	justify-content: center;
	align-items: stretch;	
	padding: 3rem 2rem;
}

main {
	padding: 0;
	width: 100%;
	overflow: hidden;
	// FLEX
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex: 1;
	min-height: 100vh;
	background-color: #fff;
}

article {
	padding: 6rem 1rem;
	background-color: transparent;
}


ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: inline-block;

	li {
		// display: inline-block;
	}
}

