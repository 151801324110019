// @import 'config/_config';

#landing-root {
	$break: 800px;
	$max-content-w: 1200px;

	#photo-section {
		// $photo-url: '../graphics/7383.jpg'; // soledad cross
		$photo-url: '../graphics/great_wall-web-1080-partcrop.jpg';
		background-image: url($photo-url);
		background-size: cover;
		// background-position: 80% 100%;
		background-position: 0% 0%;
		position: relative;
		.sec-content {
			position: relative;
			margin: auto;
			margin-top: 20vh;
			width: 80%;
			max-width: $max-content-w;
			text-align: left;
				// position: absolute;
				// margin: 0 auto;
				// width: 100%;
				// top: 40%;
				// left: 4rem;
				// transform: translateX(-50%);

			// --------------------------------------------------
			// For narrow screens, align center rather than left
			// --------------------------------------------------
			@media all and (max-width: 420px) {
				width: auto;
			}

			.sec-headline {
				color: $light-primary;
				margin-bottom: 0.25rem;
				font-size: 2.5rem;
				font-weight: 200;
				letter-spacing: 0.05em;
				line-height: 1.5;

			}
			.sec-subheadline {
				color: $light-secondary;
				color: $light-primary;
				font-size: 1rem;
				font-weight: 500;
				letter-spacing: 0.15em;
				margin-bottom: 2rem;
			}

			.ws-btn {
				// margin-top: 1rem;
				padding: 0 3rem;
				background-color: rgba(0,0,0,0.38);
				font-weight: 300;
			}
		}
	}

	#dev-section {
		.content-container {
			flex: 5;
		}
		.sec-content {
			max-width: 500px;
		}
		.sec-headline {
			font-weight: 600;
			span {
				color: #fff9a3;
			}
		}
		.subsection {
			@media all and (max-width: $break) {
				min-height: 300px;
				width: 100%;
				display: block;
				&:first-child {
					padding-bottom: 1rem;
				}
				&:last-child {
					padding-top: 1rem;
				}
			}
		}
	}
	
	.ws-header {
		background-color: #ecf0f1;
		color: rgba(0,0,0,0.8);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.headline {
			font-size: 2.4rem;
			font-weight: 500;
			letter-spacing: -1px;
		}
	}

	.landing-section {
		padding: 0;
		// padding-left: 0;
		// padding-right: 0;
		display: flex;
		flex-direction: row;
		justify-content: stretch;
		align-items: stretch;
		min-height: 100vh;
		min-width: 100vw;
		height: 101vh;

		@media all and (max-width: $break) {
			flex-direction: column;
		}

		.section-body {
			width: 100%;
			margin: 0 auto;
			max-width: 800px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			@media all and (max-width: $break) {
				flex-direction: column;
			}
		}

		.subsection {
			flex: 1;
			margin: 0; // Setting margin:auto or height will prevent flex:stretch from working
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 2rem;
		}

		.content-container {
			// max-width: 500px;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}

		.sec-content {
			text-align: center;
			.sec-headline {
				font-size: 2rem;
				font-weight: 700;
				letter-spacing: 0.5px;
				margin-bottom: 1.2rem;
			}
			.sec-subheadline {
				font-size: 1.3rem;
				font-weight: 300;
				letter-spacing: 0.6px;
				margin-bottom: 1.2rem;
			}
		}

		.decor-container {
			overflow: hidden;
			max-height: 100%;
			flex: 4;
			@media all and (max-width: $break) {
				max-height: 50%;
			}

			.decor {
				min-width: inherit;
				position: absolute;
				right: 2rem;
				bottom: 6rem;
				// transform: translateY(50%);
				overflow: hidden;
				margin: auto;
				height: 130vh;

				@media all and (max-width: $break) {
					bottom: 20%;
					left: 15%;
					// transform: translate(70%, -10%);
					height: 90vh;
				}
			}
		}

		&#contact-section {
			display: flex;
			background-color: $bg-light;
			// flex-direction: row;
			justify-content: center;
			align-items: center;
			.subsection {
				// max-width: 500px;
				padding: 1rem 1.5rem;
			}
			.content-container {
				// max-height: 250px;
				max-width: 500px;
			}
			.cluster-container {
				max-width: 300px;
			}
			.link-cluster {
				min-width: 300px;
				a {
					padding: 0.5em 0.6em 0.7em;
				}
			}
			@media all and (max-width: $break) {
				.subsection {
					width: 90%;
					max-width: 600px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					min-height: initial;

					&:first-child {
						justify-content: flex-end;
						flex: 1;
					}
					&:last-child {
						justify-content: flex-start;
						flex: 1;
					}
				}
				.cluster-container {
					// max-height: 100px;
					max-width: 320px;
				}
			}
		}
	}

	.dark-alt {
		background-color: #474d57;
		background-color: #494f58;
		// background-color: #4e545f;
		* {
			color: $light-primary;
		}
		.sec-subheadline {
			color: $light-secondary;
		}
	}
}

#temp-landing-root {
	padding: 0 2rem;
	.content {
		background-color: transparent;
		max-width: 500px;
		text-align: center;
		
		
		p {
			font-weight: 400;
			color: $text-primary;
			margin-bottom: 2em;
			line-height: 1.6;
			text-align: left;
		}
		a {
			font-size: 2rem;

		}
	}
}