@import "../config/variables";

.btn {
	display: inline-block;
	padding: 0 1em;
	line-height: 2.4;
	
	color: $fg-primary;
	border-radius: .33em;
	text-decoration: none;
	font-weight: 300;
	background-color: $opaque-light;
	// border: 2px solid transparent;

	&:hover,&:focus {
		background-color: $opaque-light-active;
	}
	// &:focus, &:active {
	// 	border-color: $blue;
	// }
}

.btn-primary {
	background-color: $blue;
	color: $fg-light-primary;
}

.btn-opaque-dark {
	background-color: $opaque-dark;
	color: #D8D8D8;
	color: $fg-secondary;
	color: $fg-light-secondary;
}
.btn-opaque-light {
	background-color: $opaque-light;
	color: $fg-secondary;
}

.btn-white {
	background-color: #fff;
	color: $fg-primary;
}

.btn-dark {
	background-color: $dark;
	color: $fg-light-primary;
}


.tags-list {
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	padding: .5rem 0;
	margin: 0;
}
.tag {
	background-color: $opaque-light;
	color: $fg-secondary;
	font-size: .9rem;
	margin: 4px;
	padding: 0 .75rem;
	line-height: 2;
	border-radius: .5em;
	font-weight: 300;

	&.tag-light {
		background-color: rgba(#fff,0.12);
		color: $text-on-opaque-light;
	}
}


.curtain {
	position: absolute;
	left: -1px;
	width: 101%;
	z-index: $zidx-top;
	
	&.curtain-bottom { 
		bottom: -1px;
		transform: translateY(10%); 
	}
	&.curtain-top    { 
		top:    -1px; 
		transform: translateY(-10%);
	}
	&.curtain-left   { left:   -1px; }
	&.curtain-right  { right:  -1px; }
}

.tug-down {
	$y: 15px;
	$animation: tug-down 10s infinite ease-in-out;
	-webkit-animation: $animation;
	animation        : $animation;
	
	@keyframes tug-down {
		0%   { transform: translateY(0px); }
		3%   { transform: translateY($y);  }
		10%  { transform: translateY(0px); }
	}
	// @keyframes tug-down {
	// 	0%   { transform: translateY(0px); }
	// 	4%  { transform: translateY($y); }
	// 	10%  { transform: translateY($y * 0.25); }
	// 	12%  { transform: translateY($y); }
	// 	20%  { transform: translateY(0px); }
	// }

}