
.photo-gallery-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;

	// margin: 0 auto;
	position: relative;

}

.gallery-grid {
	.thumbnail {
		max-width:  200px;
		max-height: 200px;

		.thumb-link,.thumb-img {
			width: 100%;
			height: 100%;
			// max-width: 100%;
			// max-height: 100%;
		}
	}
}
