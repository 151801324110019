
.portal-container {
	height: 100vh;
	width: 100%;
	max-width: 100vw;
	display: fixed;
	top: 0;
	left: 0;

	&.portal-card {
		height: 80vh;
		width: 80vw;
		max-width: 300px;
		max-height: 500px;
		border-radius: 20px;
		margin: 1rem;
		overflow: hidden;
	}

	.portal-modal {
		height: 80vh;
		width: 90vw;
		max-width: 700px;
		margin: auto;
		display: block;
		overflow-y: scroll;
		overflow-x: hidden;
		position: relative;
		
		.ws-btn {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	
}