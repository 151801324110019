@import '../../config/variables';

.landing-minimal {
	main {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@include gradient(#ebedee,#fdfbfb,180deg,-20%,0%);
		// background-color: #ebedee;
	}
	// background-color: linear-gradient(180deg,#ebedee -20%,#fdfbfb);
}

.bladerunner {
	.content {
		font-size: 1.5rem;
		text-transform: uppercase;
		font-weight: 300;
		letter-spacing: .2em;
		word-spacing: .2em;
		line-height: 4;
		padding: 0 1rem;
		* {
			font-size: inherit;
		}
	}
	.link-text {
		color: $link;
	}
	.headline {
		font-size: 1.2em;
		font-weight: 300;
	}
}

.hero-list {
	font-size: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;

	li {
		flex: 1;
		font-size: inherit;
		// padding: .5rem;
		padding: 0;
		line-height: 2;
		font-weight: 700;
		text-transform: lowercase;
		padding: 0 .5rem;
		text-decoration: underline;
		color: #000;
		> * {
			width: 100%;
			display: inline-block;
			font-size: inherit;
		}
	}
	// a {
	// 	font-size: inherit;
	// 	display: inline-block;
	// 	width: 100%;
	// }
	.hero-title,
	.hero-link {
		// line-height: 2;
		font-weight: 700;
		text-transform: lowercase;
		padding: 0 .5rem;
		color: #000;
	}
	.hero-title {
		font-weight: 400;
		text-transform: uppercase;
		font-size: .75em;
		letter-spacing: .1em;
		line-height: 1.5em;
		margin-bottom: 2rem;
	}
	.hero-link {
		// line-height: 2;
		// font-weight: 700;
		// text-transform: lowercase;
		// color: #000;
		// padding: 0 .5rem;
		text-decoration: underline;
		text-decoration: none;

		&:hover,
		&:focus {
			// color: #08c;
			text-decoration: none;
			text-decoration: underline;
		}
	}

	.hero-text-blocks {
		width: 100%;
		margin: .5rem;
		background-color: #000;
		color: #fff;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: .05em;
		line-height: 2.5;
		padding: 0 2em 0 1em;
	}
}
