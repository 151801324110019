.info-root {
	.eyebrow {
		margin: 0;
	}
	.site-title {
		font-size: 2rem;
		margin-bottom: 1em;
		margin-top: .25em;
		// font-weight: $bold;
	}

	ul {
		font-size: .9rem;
		span {
			font-weight: $bold;
			margin-right: 1rem;
			&::after {
				content: ":"
			}
		}
	}
}