@import "../../config/variables";

/// Modal Root that exists outside of the app's root ----------
//   - sibling to #root
//   - NOT the overlay / backdrop
//   - No "styling" here
//   - Purpose is to ensure it covers the app properly (modal on top, fills window, etc)
///------------------------------------------------------------
.w-modal-root {
	position: relative;
	// position: fixed;
	// height: 100vh;
	// width: 100vw;
	height: initial;
	width: 100%;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	// z-index: 100000;
}

/// Container just inside the modal-root ----------------------
//  - handles styling of the backdrop that will cover the app (overlay)
///------------------------------------------------------------
.w-modal-overlay {
	min-height: 100vh;
	width: 100%;
	margin: 0;
	padding: 3rem;
	// padding: 5vh 10%;
	background-color: rgba(0,0,0,0.25);
	display: none; // * IMPORTANT: hide overlay by default

	@media all and (max-width: $break-s){
		padding: 4rem 2rem;
		// padding: 5vh 5%;
	}

	&.w-modal-overlay_after-open {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}


/// "HIDDEN" Modal Window ---------------------------------
//   - Generally contains properties that are independent 
//     of modal state (open or closed)
//   - HOWEVER, modal's default state is closed, so it 
//     should be hidden initially as the starting point
//     and have the active style in another class (w-modal_after-open)
///---------------------------------------------------------
.w-modal {
	height: initial;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	margin: 0;
	padding: 0;
	position: relative;
	overflow: hidden; // hides corners of action bar

	.overlay-btn {
		background-color: rgba(#000,0.4);
		position: absolute;
		top: 1rem;
		right: 1rem;
		z-index: $zidx-top;
		font-size: 1.2rem;
		width: 1.2em;
		line-height: 1.2em;
		// height: 2em;
		text-align: center;
		cursor: pointer;
		border-radius: 50%;
		opacity: 0.81;

		&:hover { opacity: 1; }

		span {
			color: $fg-light-primary;
		}
	}
}


//+  VISIBLE Modal Window ------------------------------------
//   - ACTIVE, VISIBLE state of the modal window
//   - This class is toggled on the modal when opened/closed
.w-modal_after-open {
	display: block; /*** IMPORTANT ***/
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
}



/// Change Position Type for the root wrappers so <body> dimensions 
//  is determined by the modal's size rather than the app's root dimensions
.w-modal_is-open {
	#root {
		position: fixed;
		filter: blur(8px);
	}
	#outer-space {
		position: relative;
	}
}



/// Modal Sections, Elements _____________________________

.modal-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 2rem 1rem;
}

.modal-header {
	font-size: 1rem;
	color: $fg-primary;
	text-align: center;
	* {
		margin-bottom: 1.25rem;
	}
	.headline {
		font-size: 2em;
		font-weight: 500;
		color: $fg-primary;
	}
	.subheadline {
		font-size: 1.5em;
		font-weight: 400;
		color: $fg-secondary;
	}
	.eyebrow {
		font-size: .9em;
		font-weight: 700;
		color: $fg-secondary;
		text-transform: uppercase;
		letter-spacing: 0px;
	}

	@media all and (max-width: $break-s){
		font-size: .9rem;
	}
}

.modal-footer {

}

/// Modal Elements _____________________________
.modal-title {
	font-size: 1.75rem;
	line-height: 1.25;
	margin-bottom: 2rem;
}


/// Buttons,Triggers __________________________
.modal-trigger {
	z-index: $zidx-top + 50;
	border: none;
	cursor: pointer;
	&:focus {
		outline: none;
	}
}

.default-trigger {
	position: fixed;
	top: 1rem;
	left: 1rem;
}

.close-btn {
	$p: 1rem;
	position: fixed;
	color: inherit;
	background-color: transparent;

	&.pos-left { left: $p; }
	&.pos-top { top: $p; }
	&.pos-right { right: $p; }
	&.pos-bottom { bottom: $p; }
}






// Default Modal _______________________
.default-modal {
	background-color: #fff;
	border-radius: 0.5rem;
	overflow-x: hidden;
	padding: 2rem 3rem;
	box-shadow: $big-box-shadow;
	@media all and (max-width: $break-s){
		padding: .5rem 1.5rem;
		font-size: 1.2rem;
	}
	&:focus {
		outline: none;
		// border: 2px solid $blue;
		box-shadow: $big-box-shadow-focused;
	}
}

.top-left-modal-root {
	.w-modal-overlay {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
	.w-modal {
		margin: 0;
	}

	.close-btn {
		color: #fff;
	}
}

// Full Page Modal ___________________________
$fullpg-theme: #fff;
.fullpage-modal-root {
	.w-modal-overlay {
		padding: 0;
		// background-color: rgba($fullpg-theme,0.5);
		background-color: rgba(#000,0.25);
	}
}
.fullpage-modal {

	background-color: rgba($fullpg-theme,0.95);
	border-radius: 0;
	overflow-x: hidden;
	padding: 2rem 3rem;
	box-shadow: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100vw;
	height: 100vh;
	
	// &:focus {
	// 	outline: none;
	// }
	.close-btn {
		position: absolute;
	}

	@media all and (max-width: $break-s){
		padding: 1rem 1.5rem;
		font-size: 1.2rem;
	}
}
