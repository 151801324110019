// Import Tailwind CSS
@import 'index.css';

// Import custom stuff
@import "./config/variables";
@import "./style/typography";
@import "./style/elements";
@import './components/DarkMode/DarkMode';

html {
	font-size: 16px;
	background-color: $bg-light;
	font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	height: 100%;
	width: 100%;
	min-height: 100vh;
	overflow: auto;
}

// Reset margins
h1,h2,h3,h4,h5,h6,p {
	margin-top: 0;
	margin-bottom: 0;
}

#root {
	position: relative;
	height: 100%;
	width: 100%;
	z-index: $zidx-low;
}

#outer-space {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: $zidx-top;
}

.page-root {
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items:     stretch;
	z-index: $zidx-low + 10;
	position: relative;
}


.content-container {
	margin: auto;
	max-width: 800px;
}

main {
	// height: 100%;
	// width: 100%;
	// min-height: 100vh;
	// min-width: 100vw;
	// display: flex;
	// flex-direction: row;
	// justify-content: center;
	// align-items: center;
}

h1 {
	font-size: 3rem;
	font-weight: 700;
}
h2 {
	font-size: 2rem;
	font-weight: 600;
}
h3 {
	font-size: 1.8rem;
	font-weight: 600;
}
h4 {
	font-size: 1.6rem;
	font-weight: 500;
}
h5 {
	font-size: 1.4rem;
	font-weight: 500;
}
h6 {
	font-size: 0.9rem;
	font-weight: 500;
}