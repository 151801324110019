@import "../../config/variables";

.btn {
	padding: 0 1em;
	line-height: 2;
	background-color: $light;
	color: $fg-primary;
	border-radius: 4px;
}

.ws-btn,
.btn {
	&:focus,
	&:hover {
		// background-color: rgba(#000,0.24) !important;
		background-color: rgba(#000,0.12) !important;
		text-decoration: none !important;
	}
}

.theme-dark {
	.ws-btn,
	.btn {
		&:focus,
		&:hover {
			// background-color: rgba(#000,0.24) !important;
			background-color: rgba(#fff,1) !important;
			text-decoration: none !important;
		}
	}
}