.theme-dark {
	#root,
	main,
	.page-root,
	body {
		background-color: $dark;
		background: $dark;
	}

	// p,
	article,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $fg-light-primary !important;
	}
	table,
	p {
		color: $fg-light-secondary !important;
	}

	.link-text,
	a {
		color: $yellow;
		color: #6cf;
	}

	.with-dividers {
		a,
		li {
			border-color: $fg-light-divider;
		}
	}

	table {
	}
}
