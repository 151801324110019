// @import '../vendor/_github-markdown';
.ws-markdown {
	font-family: $native-sans-serif;
	* {
		line-height: 1.5;
		// margin: .5rem;
	}
	> div > * {
		margin: .5rem;
	}
	h3 {
		margin-top: 0.8em;
	}
	h4 {
		margin-top: 2.4em;
		font-weight: 700;
		color: $text-primary;
		font-size: 1.3rem;
		text-align: right;
	}
	p {
		font-weight: 300;
	}
	ul {
		list-style-type: disc;
		padding-left: 2rem;
		li {
			display: list-item;
			font-size: 1.2rem;
		}
	}
}

