//-----------------------------
//  Layout 
//-----------------------------
// Margins ....................
//   .no-margin-top
//   .no-margin-right
//   .no-margin-bottom
//   .no-margin-left
//   .no-margin-x
//   .no-margin-y
//   .thin-margin
// Padding ....................
//   .no-padding-top
//   .no-padding-right
//   .no-padding-bottom
//   .no-padding-left
//   .no-padding-x
//   .no-padding-y
//   .thin-padding
// Bumpers ....................
//   .nav-bumper
//   .y-bumpers
//   .x-bumpers
//   .bumpers
//   .no-bumpers-x   (.no-padding-x)
//   .no-bumpers-y   (.no-padding-y)
// Other ......................
//   .root-fill-page
//   .fill-window
//   .can-wrap
//   .sec-row
//   .sec-col
//.............................


.root-fill-page {
	width: 100%;
	min-height: 100vh;
	height: 100vh;
	margin: 0;
}
.fill-window {
	min-height: 100vh;
	min-width: 100vw;
	margin: 0;
}

.can-wrap {
	flex-wrap: wrap;
}


// ===== Alignment / Flex ===== //
.sec-row {
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	align-items: stretch;
	margin: 0;
}
.sec-col {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
}


//===== Page Bumpers =====//


	.nav-bumper {
		padding-top: $nav-height;
	}

	.y-bumpers {
		padding-top:    $bumper-y + $nav-height;
		padding-bottom: $bumper-y;
	}

	.x-bumpers {
		padding-left:  $bumper-x;
		padding-right: $bumper-x;
	}

	.bumpers {
		@extend .x-bumpers;
		@extend .y-bumpers;
	}

	.no-bumpers-x {
		padding-left: unset;
		padding-right: unset;
	}

	.no-bumpers-y {
		padding-top: unset;
		padding-bottom: unset;
	}
	
//===== Padding =====//

	@each $i in 'top', 'right', 'bottom', 'left' {
		.no-padding-#{$i} {
			padding-#{$i}: 0;
		}
	};

	.no-padding-x {
		@extend .no-padding-left;
		@extend .no-padding-right;
	}
	.no-padding-y {
		@extend .no-padding-top;
		@extend .no-padding-bottom;
	}

	.thin-padding {
		padding: 1rem;
	}
//===== Margin , Spacing =====//
	
		@each $i in 'top', 'right', 'bottom', 'left' {
			.no-margin-#{$i} { margin-#{$i}: 0; }
		};
		.no-margin-x {
			@extend .no-margin-left;
			@extend .no-margin-right;
		}
		.no-margin-y {
			@extend .no-margin-top;
			@extend .no-margin-bottom;
		}
		.thin-margin {
			margin: 1rem;
		}

	
	// Spacing ------------
	.give-little-space { margin: 0.25rem }
	.give-some-space { margin: 0.5rem }
	.give-space { margin: 1rem; }
	.give-lots-of-space { margin: 2rem }


//===== Heights, Widths =====//
	.limit-width {
		width: 100%;
		max-width: $max-content-width;
		min-width: $min-content-width;
		margin: 0 auto;
	}


//===== Bottom Bar =====//
	.bottom-bar {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}


////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
