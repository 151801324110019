
.dotdotdot {
	$unit: 1em;
	font-size: 0.4rem;
	height: 3*$unit;
	line-height: 3*$unit;
	display: inline-block;
	width: initial;
	padding: 0 $unit;
	cursor: pointer;
	transition: opacity 0.4s;
	
	& > * {
		height: $unit;
		width: $unit;
		display: inline-block;
		@include border-radius(50%);
		margin-left: 0.8em;
		background-color: #fff;
		&:first-child {
			margin-left: 0;
		}
	}
}