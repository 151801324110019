// @import 'modules/_mixins';
// @import 'config/_colors';
@import '../../config/variables';

#photo-fluid-root {
	$zidx: 7000; //___ z-index of header ___//
	// $bleed-color: #080a09;
	// $bleed-color: #060807;
	$bleed-color: #05060a;

	background-color: $bleed-color;
	main {
		// background-color: $bleed-color;
		background-color: inherit;
	}
	article.photo-grid-wrap {
		padding-bottom: 8rem;
	}

	// ===== Header ===== //
	.ws-header {
		// height: 150vh;
		height: 130vh;
		position: relative;

		// 1.777777
		@media (min-aspect-ratio: 16/9) and (orientation: landscape) {
			height: 150vh;
		}
		@media (min-aspect-ratio: 2/1) and (orientation: landscape) {
			height: 160vh;
		}

		.photo-bg-wrap {
			$pct-blur: 10%;
			background-color: $bleed-color;
			$local-src: '../../style/graphics/bg/';
			$src: 'https://s3-us-west-2.amazonaws.com/assets.che.ng/img/bg/';

			.photo-bg {
				background-size: cover;
				background-position: 50% 100%;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: $zidx + 10;
				width: 100%+$pct-blur;
				height: 100%+$pct-blur;
				margin-left: -0.5*$pct-blur;
				margin-top: -0.5*$pct-blur;
			}
			.blurholder {
				filter: blur(1rem);
				z-index: $zidx+10;
				background-image: url($local-src+'skyline-l-blur.jpg');
				@media all and (orientation: 'portrait') {
					background-image: url($local-src+'skyline-p-blur.jpg');
				}
			}
			
			.full-photo-bg {
				z-index: $zidx+11;
				background-color: transparent;
				// background-image: url('https://s3-us-west-2.amazonaws.com/assets.che.ng/img/bg/skyline-l-2560.jpg');
				@media all and (orientation: 'landscape'){
					background-image: url($src+'skyline-l-3k.jpg');
				}
				@media all and (orientation: 'portrait') {
					// background-image: url('https://s3-us-west-2.amazonaws.com/assets.che.ng/img/bg/skyline-p-2560.jpg');
					background-image: url($src+'skyline-p-3k.jpg');
				}
				@media all and (orientation: 'landscape') and (max-width: 1000px){
					background-image: url($src+'skyline-l-tablet.jpg');
				}
				@media all and (orientation: 'portrait') and (max-width: 800px){
					background-image: url($src+'skyline-p-tablet.jpg');
				}
			}

			// TODO: animation between blur and loaded 
			// - note that blurred is 10% larger too, so a simple opacity change will look weird since they don't match
			// - maybe have full version start enlarged and blurred as well. on load, shrink and unblur
		}

		.above-fold {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			z-index: $zidx + 15;

			.liner {
				position: relative;
				width: 100%;
				height: 100%;
			}
		}
		.header-content {
			position: absolute;
			// top: 60vh;
			top: 25vh;
			font-size: 2.5rem;
			// bottom: 10vh;
			// bottom: 0;
			// outline: 2px solid red;
			// @include gradient(rgba($bleed-color,0),rgba($bleed-color,0.5),180deg,0%,50%);

			@media (min-aspect-ratio: 12/9) and (orientation: landscape) {
				bottom: 5vh;
				top: unset;
			}

			.headline {
				margin: 0rem auto;
			}
		}
	}

	// ===== Photo Grid ===== //
	.photo-grid-wrap {
		// * Fixes bug where there is sometimes a visible gap between header and article.
		margin-top: -1px;
		border-top: 1px solid $bleed-color;
		box-shadow: 0 1.5rem 1.5rem 0 $bleed-color;
	}
}

#photography-root {
	$zidx: 7000; //___ z-index of header ___//
	$focusProfile: 18, -5, 8, -3, 6, -3, 3, -2, 2, -1, 0;
	$focusDuration: 1s;
	$focusEase: ease-in;
	$kf0: 18;
	$kf1: 5;
	$kf2: 8;
	$kf3: 3;
	$kf4: 6;
	$kf5: 3;
	$kf6: 3;
	$kf7: 2;
	$kf8: 2;
	$kf9: 1;
	$kf10: 0;

	// ===== Header ===== //
	.ws-header,
	#photography-header {
		height: 100vh;
		position: relative;

		.photo-background {
			background-image: url('../../style/graphics/7680-blur-background.jpg');
			background-size: cover;
			background-position: 50% 20%;
			position: absolute;
			top: 0;
			left: 0;
			//___ Make slightly larger to prevent vignetting ___//
			width: 110%;
			height: 110%;
			transform: translate(-5%, -5%); // shift to center enlarged image

			z-index: $zidx + 10;
			filter: blur(18px);

			// ===== Autofocus Animation ===== //
			// &.focusing {
			// 	@keyframes camerafocus {
			// 		0% { @include blur(18px); }
			// 		30% { @include blur(0px); }
			// 		80% { @include blur(3px); }
			// 		100% { @include blur(0px); }
			// 	}
			// 	animation: $focusDuration $focusEase camerafocus;
			// 	filter: blur(0px);
			// }
			// ~ * {
			// 	z-index: $zidx + 100;
			// }
		}
	}

	.header-content {
		text-align: left;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		width: initial;
		font-size: 2rem;
		.headline {
			font-size: 1.5em;
			line-height: 1.3;
		}
		.eyebrow {
			text-align: left;
			font-size: .5em;
			font-weight: 500;
		}
		.subheadline {
			font-size: .75em;
			text-align: right;
		}
		& > * {
			margin: 0;
		}
	}
}
