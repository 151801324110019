//--------------------
//    Levels 
//--------------------

.ws-level {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	margin: 0.5rem 0;
	width: initial;
	list-style-type: none;
	
	> * {
		margin: 0;
		padding: 4px 0.7rem;
	}

	&.with-dividers {
		> * {
			border-left: 2px solid $disabled;
			&:first-child {
				border-left: none;
			}
		}
	}

	&.collapse-mid {
		@media all and (max-width: 500px){

			flex-direction: column;
			align-items: center;
			&.with-dividers {
				> * {
					border: none;
				}
			}
		}
	}
}