.gs-container {
	$gs-pad-x: 2rem;
	$gs-form-lh: 2.4em;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 90%;
	max-width: $max-content-width;
	font-size: 1.5rem;
	border-radius: 0.5em;
	overflow: hidden;
	margin: 0 auto;
	padding: 0;
	background-color: $bg-light;
	// box-shadow: $box-shadow;
	border: 1px solid #ecf0f1;

	// *:focus { outline: 2px solid red !important; }

	// Optional / Temporary .......
	// margin-top: 5rem;

	.gs-form {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: stretch;

		label {
			display: none;
		}

		.input-group {
			// margin-left: 1.5em; //___ TEMPORARY ___//
			flex: 1;
			font-size: 1em;
			position: relative;

			$input-pad-left: $gs-pad-x / 2;

			.query-suggestion,
			input {
				font-size: inherit;
				padding: 0;
				// padding-left: $input-pad-left;
				padding-left: $gs-pad-x;
				line-height: $gs-form-lh;
				width: 100%;
				font-weight: 400;

				color: $text-primary;
				box-shadow: none;
				border: none;
				outline: none;
				background-color: transparent;
				letter-spacing: 0.025em;
			}

			input::placeholder {
				color: $text-secondary;
			}

			.query-suggestion {
				position: absolute;
				top: 0;
				left: 0;
				pointer-events: none;
				// color: $text-secondary;
				color: $disabled;
			}
		}

		.search-btn {
			font-size: inherit;
			background-color: transparent;
			flex-basis: 2em;
			padding: 0;
			padding-right: $gs-pad-x;
			// padding-right: $gs-pad-x / 2;
			line-height: $gs-form-lh + 0.2em;
			height: $gs-form-lh;
			border: none;
			cursor: pointer;
			// opacity: 0.38; // inactive
			opacity: $inactive-icon-opacity;
			transition: all 200ms;

			&:hover,
			&:active,
			&:focus {
				// opacity: 0.54;
				opacity: $active-icon-opacity;
				outline: none;
			}

			img {
				height: 1em;
				width: initial;
				display: inline-block;
				margin-bottom: -2px; // optical centering
			}
		}
	}

	.gs-result-list-container {
		padding: 0;
		background-color: transparent;

		.container-liner {
			// border-top: 1px solid $dividers;
		}
		.eyebrow {
			padding-left: $gs-pad-x;
			line-height: 3;
			color: $disabled;
			background-color: rgba(0, 0, 0, 0.08);
			background-color: $u-light-gray;
			letter-spacing: 0.5px;
		}
	}

	.gs-result-group {
		background-color: #fff;
		border-top: 1px solid rgba(#000, 0.08);

		&:first-child {
			border-top: none;
		}

		.eyebrow {
			font-size: 1.1rem;
			font-weight: 400;
			text-transform: none;
			// padding: 1rem $gs-pad-x;
			background-color: transparent;
			color: $text-secondary;
			padding: 0 $gs-pad-x;
			// padding-right: 4.5rem;
			display: flex;
			justify-content: space-between;
			line-height: 2.5em;
			margin: 0;
			transition: all 200ms;

			@media all and (max-width: 450px) {
				// padding-left: 2.75rem;
				padding: 0 2rem;
			}

			.gs-res-group-count {
				color: $disabled;
				font-size: 0.7em;
			}
		}

		.gs-result-group-list {
			display: none;
		}

		// ! :focus-within not supported by Edge/IE.
		// ! :focus also doesn't work with mobile
		.gs-res-group-link {
			
			&:active,
			&:hover,
			&:focus-within,
			&:focus {
				outline: none;
				.eyebrow {
					background-color: $u-light-gray;
				}
				.gs-res-group-name {
					color: $text-primary;
				}

				.gs-result-group-list {
					display: flex;
				}
			}
		}
	}

	.gs-result-group-list,
	.gs-result-list {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
	}

	.gs-result-item {
		// border-top: 1px solid $dividers;
		// padding: 1.5rem $gs-pad-x;
		color: $text-primary;

		a {
			color: inherit;
			padding: 1.5rem $gs-pad-x;
			width: 100%;
			height: 100%;
			display: inline-block;
			display: flex;
			flex-direction: row;
			justify-content: stretch;
			align-items: stretch;

			.result-item-body {
				flex: 1;
				// outline: 1px solid blue;
			}

			.icon-container {
				flex-basis: 1rem;
				font-size: 1.7rem;
				margin: 0;
				// margin-right: 1rem;
				text-align: center;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				i,
				.res-item-icon {
					// outline: 1px solid green;
					font-size: inherit;
					color: $icon-inactive;
					color: rgba(0, 0, 0, 0.3);
				}
			}
			.arrow-icon-container {
				font-size: 1rem;
			}

			&:hover {
				background-color: $v-light-gray;
				color: inherit;
				// background-color: #fafbfb;
				i,
				.res-item-icon {
					color: $icon-active;
				}
			}
			&:active,
			&:focus {
				background-color: $blue;
				color: $light-primary;
				outline: none;

				.subtitle {
					color: $light-secondary;
				}
				i,
				.res-item-icon {
					color: $light-active;
				}
			}
		}
		.title {
			font-size: 1em;
			font-weight: 400;
			line-height: 1.5;
		}
		.subtitle {
			color: $text-secondary;
			font-size: 0.65em;
		}
	}
}
