@import '../../../config/variables';
//============================================
// Globals
// $h1-size: 2.8rem;
// $h2-size: 2.0rem;
// $h3-size: 1.8rem;
// $h4-size: 1.6rem;
// $h5-size: 1.2rem;
// $h6-size: .9rem;
// $p-size:  1.2rem;


// $textTags: (
// 	'h1': ( $h1-size, $heavy),
// 	'h2': ( $h2-size, $bold),
// 	'h3': ( $h3-size, $bold),
// 	'h4': ( $h4-size, $semibold),
// 	'h5': ( $h5-size, $semibold),
// 	'h6': ( $h6-size, $semibold),
// 	'p':  ( $p-size,  $regular),
// );


@each $tag, $props in $textTags {
	$size: nth($props,1);
	$weight: nth($props,2);

	#{$tag} {
		font-size: $size;
		font-weight: $weight;
	}

	// Example Output: 
	// h1 {
	// 	font-size: 3rem;
	// 	font-weight: 700;
	// }
}
h1,h2,h3,h4,h5,h6,p {
	line-height: 1.1;
	margin: .5rem 0;
}

/* Additional Type Adjustments */


p {
	line-height: 1.5;
	margin: 1em 0;
}


a {
	@extend p;
	color: $link;
	text-decoration: none;
	margin: 0;
}




//============================================
// Class Typography 
	.eyebrow {
		font-size: $font-XS;
		font-weight: 700;
		text-transform: uppercase;
		opacity: 0.8;
		letter-spacing: 1px;
		// &.section-label {
		// 	font-size: 1.5rem;
		// 	color: $primary;
		// 	opacity: 1;
		// }
	}
	.headline {
		font-size: $font-XL;
		font-weight: 600;
		margin: 1rem auto;
	}
	.subheadline {
		font-size: $font-L;
		font-weight: 300;
		opacity: 0.9;
		margin: 1rem auto;
	}
	.subheadline-copy {
		font-size: $font-M;
		font-weight: 400;
	}
	.fineprint {
		font-size: .75rem;
		font-weight: 500;
		letter-spacing: -0.2px;
		word-spacing: 1px;
	}


	.featured-link {
		// background-color: rgba(0,0,0,0.24);
		// color: #fff;
		background-color: #fbfdfe;
		color: $blue;
		font-size: 1.4rem;
		padding: 0.5em 1.25em;
		@include border-radius(.25em);
		margin: 2rem auto .5rem;
		display: inline-block;
	}
	.theme-dark {
		.featured-link{
			background-color: #fbfdfe;
			color: $blue;
			// background-color: rgba(#fff,0.25);
			// color: #fff;
			// background-color: rgba(#000,0.12);
			// color: $fg-light-secondary;
			// color: #000;
		}
		.subfeatured-link {
			color: #fff;
		}
	}
	.subfeatured-link {
		display: block;
		font-size: 1.25rem;
		color: #fff;
	}