
.metro-container {
	overflow: hidden;
}

.metro-map {
	min-height: 100%;
	min-width: 100%;
	height: initial;
	width: 200vw;
	position: fixed;
	top: -20%;
	left: -80%;
	transform: translate(0%,0%);

}