//------------------------------
//    Photo Grid / Gallery 
//------------------------------
@import '../../config/variables';
$grid-break-1: 1020px;
$grid-break-2: 730px;

.pswp-gallery {
// ===== Thumbnail Container ===== //
	.pswp-thumbnails {
		$min-edge: 120px;
		$max-edge: 240px;
		$max-per-row: 3;
		display: flex;
		flex-direction: row;
		max-width: $max-edge * $max-per-row;
		flex-wrap: wrap;
		width: initial;
		justify-content: flex-start; /*** IMPORTANT ***/
		align-items: flex-start;
		margin: auto;

	// ===== Each Thumbnail ===== //
		.pswp-thumbnail {
			max-width: $max-edge;
			max-height: $max-edge;
			margin: 0;
			padding: 1px;
			cursor: pointer;
			
			img {
				height: 100%;
				width: 100%;
				// cursor: pointer;
			}
			// @media (max-width: $row-3 + 20px) {
			// 	max-width: 31vw;
			// 	max-height: 31vw;
			// }
			// @media (max-width: 480px) {
			// 	min-width: 100%;
			// 	max-width: 100%;
			// 	max-height: initial;
			// 	padding: 2rem 0;
			// 	margin: 0;
			// }
		}


	// ===== Media Queries / Responsiveness ===== //
		$row-4: $max-edge * 4;	
		$row-3: $max-edge * 3;
		$row-2: $max-edge * 2;
		
		@media (min-width: $row-4 + 50px) {
			max-width: $row-4;
		}

		@media (max-width: $grid-break-1) {
			max-width: $row-3;
		}

		@media (max-width: $grid-break-2) {
			// max-width: 93vw;
			// min-width: 99%;
			width: 93vw;
			padding: 0;
			margin: 0 auto;

			.pswp-thumbnail {
				// $x: 31vw;
				$x: 31vw;
				max-width: $x;
				max-height: $x;
			}
		}
		
		
	}
}

.photo-grid-wrap {
	position: relative;
	// padding-left: 0;
	// padding-right: 0;
	padding: 4rem 0;
	z-index: 8000;

	@media all and (max-width: $grid-break-1) {
		padding: 3rem 0;
	}
	@media all and (max-width: $grid-break-2) {
		padding: 2rem 0;
	}
}

// React Photoswipe -------------------------------

// .pswp-gallery {
// 	.pswp-thumbnails {
// 		display: flex;
// 		flex-direction: row;
// 		justify-content: center;
// 		align-items: center;
// 		flex-wrap: wrap;
// 		.pswp-thumbnail {
// 			margin: 2px;
// 			cursor: pointer;
// 			img {
// 			}
// 		}
// 	}
// 	.pswp {
// 	}
// }
