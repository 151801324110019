

//================================
//  DISPLAY
//================================
.d-inblock { display: inline-block !important; }
.d-block   { display: block !important; }
.d-flex    { display: flex !important; }
.d-none    { display: none !important; }

//================================
//  FLEX
//================================

.flex-col          { flex-direction: column !important; }
.flex-row          { flex-direction: row !important; }
.f-align-center    { align-items: center !important; }
.f-align-stretch   { align-items: stretch !important; }
.f-align-between   { align-items: space-between !important; }
.f-align-around    { align-items: space-around !important; }
.f-align-start     { align-items: flex-start !important; }
.f-align-end       { align-items: flex-end !important; }
.f-justify-center  { justify-content: center !important; }
.f-justify-stretch { justify-content: stretch !important; }
.f-justify-between { justify-content: space-between !important; }
.f-justify-around  { justify-content: space-around !important; }
.f-justify-start   { justify-content: flex-start !important; }
.f-justify-end     { justify-content: flex-end !important; }
.f-wrap            { flex-wrap: wrap !important; }
.f-nowrap          { flex-wrap: nowrap !important; }
.f-wrap-reverse    { flex-wrap: wrap-reverse !important; }




.m-auto { margin: auto; }
.m-0 { margin: 0; }
// .m-1 { margin: .25rem; }
// .m-2 { margin: .5rem; }
// .m-3 { margin: .75rem; }
// .m-4 { margin: 1rem; }
// .m-5 { margin: 1.5rem; }
// .m-6 { margin: 2rem; }
// .m-7 { margin: 3rem; }
