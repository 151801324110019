// Breakpoints ................

// $a: 300px;
// $b: 500px;
// $c: 700px;
// $d: 800px;
// $e: 1200px;
// $f: 1920px;
// $g: 3840px;
// $max: $f;
// $min: $a;

// $breakpoints: (
// 	'a': ( 300  ),
// 	'b': ( 500  ),
// 	'c': ( 700  ),
// 	'd': ( 800  ),
// 	'e': ( 1200 ),
// 	'f': ( 1920 ),
// 	'g': ( 3840 )
// );


@mixin media-query($name,$min,$max) {
	$n: $name;
	$f: $min;
	$t: $max;
	@media all and (min-width: $min+'px') and (max-width: $max+'px') {
		// @media all and (min-width: $min+'px') {
		// @media all and (max-width: $max+'px') {
		@each $i in (1,2,3,4,5,6,7,8,9,10,11,12) {
			.ws-row > .ws-col._#{$name}#{$i} {
					// &#{$name}#{$i} {
						// flex: 1 1 100%/$i;
						flex-basis: 100%/$i !important;
					// }
				}
		}
	}
}

/*___ BREAKPOINT MARKER !!!!!!!!!!!!!!!! ___*/
$prev: 0;
@each $name,$range in $breakpoints {
	$break: nth($range,1);
	// $max: nth($range,2);
	
	@include media-query($name,$prev,$break);

	$prev: $break;
}


// ? Moved from grid.scss. Not sure if used.
.ws-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 0 .5rem;
	width: 100%;
	
	& > * {
		flex: 1;
		@extend .min-xs;
		margin: .5rem;
	}

}


// .ws-grid {
// 	.ws-row {
// 		display: flex;
// 		flex-direction: row;
// 		justify-content: stretch;
// 		align-items: stretch;
// 		margin: 0;
// 		padding: 0;
// 		width: 100%;
// 		// background-color: rgba(0,0,0,0.1);
// 		background-color: $bg-dark;
// 		max-width: $max;
// 		flex-wrap: wrap;

// 		& > .ws-col {
// 			flex: 1;
// 			margin: 0.25rem 0.5rem;
// 			padding: 2rem 1rem;
// 			// background-color: rgba(0,0,0,0.1);
// 			// @extend .ws-primary;
// 			// min-width: $min;
// 			background-color: $dark-gray;
// 			// flex-basis: 100%;

// 			@media all and (max-width: $a) {
// 				background-color: $red;
// 			}
// 			@media all and (min-width: $a) and (max-width: $b) {
// 				background-color: $green;
// 			}
// 			@media all and (min-width: $b) and (max-width: $c) {
// 				background-color: $yellow;
// 			}
// 			@media all and (min-width: $c) and (max-width: $d) {
// 				background-color: $blue;
// 			}
// 			@media all and (min-width: $d) and (max-width: $e) {
// 				background-color: $orange;
// 			}
// 			@media all and (min-width: $e) and (max-width: $f) {
// 				background-color: $purple;
// 			}
// 			@media all and (min-width: $f) and (max-width: $g) {
// 				background-color: $bg-light;
// 			}

// 		}
// 	}
// }

