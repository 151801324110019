.ws-cloak {
	position: fixed;
	height: 100vh;
	width: 100vw;
	z-index: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	// prevent accidental dragging
	pointer-events: none;
	touch-action: none;
	.cloak-content {
		z-index: inherit;
		// top: 50%;
		// left: 50%;
		// transform: translate(-50%, -50%);
		text-align: center;
		.preloader {
			z-index: inherit;
			display: inline-block;
			height: initial;
			width: initial;
		}
		.headline {
			color: #999;
		}
	}
}

.aframe-wrap a-scene {
	&.loading {
		z-index: 900;
	}
	&.loaded {
		z-index: 940;
	}
}