
@mixin vend($property,$value){
	-webkit-#{$property}: $value;
	   -moz-#{$property}: $value;
	    -ms-#{$property}: $value;
	     -o-#{$property}: $value;
	        #{$property}: $value;
}

// @mixin border-radius($radius: 5px){
// 	-webkit-border-radius: $radius;
// 	-moz-border-radius: $radius;
// 	-ms-border-radius: $radius;
// 	-o-border-radius: $radius;
// 	border-radius: $radius;
// }
@mixin border-radius($radius: 5px){
	@include vend('border-radius',$radius)
}
@mixin box-shadow($shadow){
	@include vend('box-shadow',$shadow)
}
@mixin blur($blurAmount: 5px){
	@include vend('filter', blur($blurAmount))
}
@mixin transition($transitions){
	@include vend('transition',$transitions)
}

//===== Gradient =====//
	@mixin gradient( $color-1, $color-2, $deg, $pct-1, $pct-2 ){
		background: $color-1;
		background: -webkit-linear-gradient( $deg, $color-1 $pct-1, $color-2 $pct-2 );
		background:    -moz-linear-gradient( $deg, $color-1 $pct-1, $color-2 $pct-2 );
		background:         linear-gradient( $deg, $color-1 $pct-1, $color-2 $pct-2 );
		// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='"'$color-1'"', endColorstr='"'$color-2'"', GradientType=1 );
	}

//===== ANIMATION =====//
@mixin animation($animation, $count: infinite, $duration: 5s, $delay: 0ms){
  -webkit-animation: $animation;
     -moz-animation: $animation;
      -ms-animation: $animation;
       -o-animation: $animation;
					animation: $animation;	
  -webkit-animation-iteration-count: $count;
     -moz-animation-iteration-count: $count;
      -ms-animation-iteration-count: $count;
       -o-animation-iteration-count: $count;
					animation-iteration-count: $count;	
  -webkit-animation-delay: $delay;
     -moz-animation-delay: $delay;
      -ms-animation-delay: $delay;
       -o-animation-delay: $delay;
					animation-delay: $delay;	
  -webkit-animation-duration: $duration;
     -moz-animation-duration: $duration;
      -ms-animation-duration: $duration;
       -o-animation-duration: $duration;
					animation-duration: $duration;					
}

@mixin keyframes($animationName) {
	@-webkit-keyframes #{$animationName} { @content; }
	@-moz-keyframes #{$animationName} { @content; }
	@-o-keyframes #{$animationName} { @content; }
	@keyframes #{$animationName} { @content; }
}

@mixin build-keyframes( $name, $profile, $full-property, $units){};

@mixin slide-animation($from,$to,$dur){
	$duration: $dur,$dur;
	$animation: sliding ease-in-out;
	@include animation($animation, infinite, $duration, 0s); 
	@keyframes sliding {
		0%   { transform: translateX($from);  }
		50%  { transform: translateX($to);    }
		100% { transform: translateX($from);  }
	}
}


