@import '../../config/variables';
//////////////////////////////////////////////

$bm-x-btn-size: 2rem; // default: 24px
$bm-x-btn-border-width: 2px;
$bm-zidx: $zidx-ultimate;

//////////////////////////////////////////////
.theme-dark {
	.globalnav {
		color: $fg-light-primary;
	}
}
.theme-light {
	.globalnav {
		.gn-title,
		.gn-list a {
			&:focus,
			&:hover {
				background-color: rgba(#999, 0.12) !important;
			}
		}
	}
}
// Default Nav
.globalnav {
	display: flex;
	justify-content: center;
	align-items: stretch;
	font-size: 1rem;
	background-color: transparent;
	line-height: $nav-height;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 8000;
	box-shadow: none;
	color: $fg-primary;

	%nav-btn {
		font-size: 1rem;
		font-weight: 300;
		@include border-radius();
		color: inherit;
		display: inline-block;
		height: initial;
		line-height: initial;
		padding: .5rem 1rem;
		text-decoration: none;
		letter-spacing: 0.05em;
		letter-spacing: 0.025em;
		&:focus,
		&:hover {
			background-color: rgba(#000, 0.12) !important;
			text-decoration: none !important;
		}
	}

	.title,
	.gn-title {
		@extend %nav-btn;
		word-spacing: 2px;
		text-transform: lowercase;
		font-size: 1.2rem;
		font-weight: 600;
		letter-spacing: 0.025em;
		// font-size: 1.1rem;
		// font-weight: 500;
		// letter-spacing: 0.05em;
	}
	.wrap,
	.gn-wrap {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 1000px;
		padding: 0 24px;
		color: inherit;
	}
	.nav-list,
	.gn-list {
		list-style-type: none;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0;
		li {
			padding: 0 .5rem;
			color: inherit;
		}
		a {
			@extend %nav-btn;
		}
	}
	.gn-burger {
		height: inherit;
		line-height: inherit;
		margin: 0;
		padding: 0 1rem;
		cursor: pointer;
		i {
			height: inherit;
			line-height: inherit;
			font-size: 1.1em;
		}
		img {
			height: 1em;
			display: inline-block;
		}
	}
}

// React Burger ====================================

// Burger Menu Hierarchy ===========================
// div.gn-bm-wrap
// | div.bm-menu-wrap.gn-menu
// | | div.bm-menu
// | | | nav.bm-item-list
// | | | | ul.nav-list.bm-item.burger-nav-list.gn-list
// | | | | | li.nav-list-item
// | | | | | | a.nav-link
// | div.bm-overlay
// | div
// | | div.bm-burger-button
// | | | svg
// | | | | button
//==================================================

.gn-bm-wrap {
	z-index: $bm-zidx;
	// outline: 1px solid red;
	// font-size: 1.2em;
	font-size: 1.2rem;
	position: relative;
	// width: 1em;
	width: 3em; /* Add "padding" by increasing width */
	height: 64px;
	padding: 0;
	background-color: transparent;
	text-align: center;
	// display: flex;
	// justify-content: center;
	// align-items: center;

	> div {
		display: inline-block;
		// position: absolute;
		// top: 0;
		// right: 0;
		flex: 1;
	}
}

// Position and sizing of burger button
.bm-burger-button {
	button {
		@extend .gn-burger;
	}
	display: inline-block;
	font-size: inherit;
	height: 1em; /*** Determines the fontawesome icon size ***/
	// padding: 0 1rem;
	// outline: 1px solid blue;
	display: flex;
	justify-content: center;
	align-items: center;

	.bm-icon {
		font-size: inherit;
		height: 1em;
		line-height: 1em;
		width: initial;
		flex: 1;
		max-width: 1em;
		max-height: 1em;
		// outline: 1px solid green;
	}

	/* Color/shape of burger icon bars */
	.bm-burger-bars {
		background: #373a47;
		width: inherit;
	}
}

// .gn-menu,
.bm-menu-wrap {
	top: 0px;
	background-color: inherit;
	width: 81%;
	max-width: 300px;

	nav {
		background-color: inherit;
		color: inherit;
		box-shadow: none;
	}

	// General Side Nav Styles ............
	.bm-menu {
		background: $wet-asphalt;
		background: $asphalt;
		padding: 2.5em 1.5em 0;
		font-size: 1.15em;
	}
}

// Cross / Close Button =============================
// Position and sizing of clickable cross button
.bm-cross-button {
	height: $bm-x-btn-size !important;
	width: $bm-x-btn-size !important;
}
// Color/shape of close button cross
// .bm-cross {
// 	background: $gray;
// }
.burger-close-btn {
	$cross-color: $gray;
	$cross-zdx: $bm-zidx + 10;
	right: 1rem;
	top: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;

	.bm-cross {
		background: transparent;
		flex: 1;
		color: $cross-color;
		max-width: 60%;
		max-height: 60%;
		z-index: $cross-zdx+1;
		pointer-events: none;
		// touch-action: none;
	}

	button {
		border-radius: 50%;
		border: $bm-x-btn-border-width solid transparent !important;
		// z-index: $cross-zdx ;
		&:focus,
		&:hover {
			outline: none;
			// border-color: $cross-color !important;

			background: rgba(#000, 0.5) !important;
		}
	}
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
	color: #fff;
	a {
		color: #fff !important;
	}
}

// Styling of overlay ..........
.bm-overlay {
	background: rgba(#101011,0.81) !important;
	background: rgba(#000,0.81) !important;
	// background: rgba($wet-asphalt,0.69) !important;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	right: 0;
}
// #burger-nav,
// .burger-nav-list.gn-menu,
.burger-nav-list {
	text-align: left;
	&:focus {
		// focuses on this element by default after opening menu
		outline: none;
	}
	li {
		display: block;
		a.nav-link {
			// font-size: 1.7rem !important;
			font-size: 1.7rem;
			&:focus,
			&:hover {
				outline: none;
				background-color: rgba(#000, 0.2) !important;
				color: #fff !important;
				// color: inherit;
			}
		}
	}
}
///////////////////////////////////////////
// MENU SWAP (Important!)
.gn-bm-wrap {
	display: none;
} // hide by default

$swap-point: 500px;
@media (max-width: $swap-point) {
	.gn-bm-wrap {
		display: inline-block;
	}
	#default-nav {
		display: none !important;
	}
}
