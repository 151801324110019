.link-cluster {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0;
	margin: 0;
	box-sizing: initial;
	min-width: 350px;

	li {
		margin: 1rem;
		a {
			font-size: 1.8rem;
			display: inline-block;
			// padding: 0.7em;
			padding: 0.6em 0.7em 0.8em;
			height: 1em;
			width: 1em;
			opacity: 0.7;
			transition: 500ms;
			// border: 1px solid #000;
			border-radius: 1.2em;

			&:hover {
				opacity: 0.9;
			}
			img {
				display: inline-block;
				height: inherit;
				width: initial;
			}
		}
	}
}


