@import '../../config/variables';


.portfolio-section {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6rem 2rem;
	font-size: 1.1rem;
	background-color: #fff;
	width: 100%;
	min-height: 80vh;
	position: relative;

	.section-body {
		max-width: 800px;
		flex: 1;
	}

	&.curtain-expand {
		min-height: 100vh;
	}
	&.curtain-shrink {
		min-height: 70vh;
	}
	.headline {
		font-size: 2.25em;
		font-weight: 700;
		line-height: 1.5;
		color: $fg-primary;
		letter-spacing: .5px;
	}
	
	.subheadline {
		font-size: 1.2em;
		font-weight: 300;
		color: $fg-secondary;
		letter-spacing: .25px;
	}
	
	.contributions-list {
		font-size: 1.1em;
		font-weight: 400;
		color: $fg-primary;
		letter-spacing: .25px;
		margin-bottom: 4rem;
		padding-left: 2rem;
		list-style-type: disc;
		line-height: 1.25;
	}
	.contribution {
		padding-left: .5rem;
		margin: 1rem 0;
	}

	.actions {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		border-top: 2px solid $opaque-light;
		padding-top: 1rem;
		margin-top: 1rem;
		> * {
			margin-left: 1rem;
		}
	}
	.action-secondary {
		background-color: $opaque-light;
		color: $fg-secondary;
	}
	.action-primary {
		background-color: $dark;
		color: $fg-light-primary;
	}
}



.dark-theme {
	background-color: $dark;
	* {
		color: $fg-light-primary;
	}

	.contributions-list,
	.headline {
		color: $fg-light-primary;
	}
	.eyebrow,
	.subheadline {
		color: $fg-light-secondary;
	}


	.headline {
		font-weight: 600;
	}
	.subheadline {
		font-weight: 100;
	}
	.contributions-list {
		font-weight: 300;
	}



	.tag {
		background-color: rgba(#fff,0.12);
		color: $text-on-opaque-light;
	}

	.actions {
		border-color: $divider-light;
	}
	.action-secondary {
		background-color: $opaque-dark;
		color: #D8D8D8;
		color: $fg-secondary;
		color: $fg-light-secondary;
	}

	.action-primary {
		background-color: #fff;
		color: $fg-primary;
	}
}

.light-theme {
	background-color: $light;
}