@import '_mixins';

$gradients: (
	'concrete' : (#657181, #3b4048, -15deg, 30%, 130%),
	'sky2' : (#36d1f5, #2189e2, -15deg, 30%, 130%),
	'blue-sky' : (#2bc3e6, #39a6da, -15deg, 30%, 130%),
	'sky' : (#2fd4e8, #0068bb, -15deg, 30%, 130%),
	'blue' : (#21d4fd, #b721ff, 19deg, 0%, 100%),
	'orange' : (#ffe53b, #ff2525, 147deg, 0%, 100%),
	'yellow-pink': (#fee140, #fa709a, 160deg, 0%, 100%),
	'green-blue' : (#08aeea, #2af598, 0deg, 0%, 100%),
	'pink' : (#ff9a9e, #fad0c4, 0deg, 0%, 100%),
	'peach' : (#fcb69f, #fad0c4, 0deg, 0%, 100%),
	'pink2' : (#fecfef, #ff9a9e, 0deg, 0%, 100%),
	'winter' : (#c2e9fb, #a1c4fd, 0deg, 0%, 100%),
	'grey-blue' : (#c2e9fb, #a1c4fd, 0deg, 0%, 100%),
	'white-cloud': (#ebedee, #fdfbfb, 180deg, -20%, 100%),
	'white-blue' : (#c3cfe2, #f5f7fa, 0deg, 0%, 100%),
	'plum' : (#764ba2, #667eea, 0deg, 0%, 100%),
	'white-pink' : (#e2d1c3, #fdfcfb, 0deg, 0%, 100%),
	'bright-blue': (#66a6ff, #89f7fe, 0deg, 0%, 100%),
	'pink-peach' : (#f5efef, #feada6, 0deg, 0%, 100%),
	'whale' : (#6991c7, #a3bded, 0deg, 0%, 100%),
	'aqua' : (#80d0c7, #13547a, 0deg, 0%, 100%),
	'mirror' : (#93a5cf, #e4efe9, 0deg, 0%, 100%),
	'dark' : (#434343, #000000, 0deg, 0%, 100%),
	'lake' : (#93a5cf, #e4efe9, 0deg, 0%, 100%),
	'passion' : (#ff758c, #ff7eb3, 0deg, 0%, 100%),
	'mountain' : (#868f96, #596164, 0deg, 0%, 100%),
	'desert' : (#c79081, #dfa579, 0deg, 0%, 100%),
	'eternal' : (#09203f, #537895, 0deg, 0%, 100%),
	'water' : (#96deda, #50c9c3, 0deg, 0%, 100%),
	'stance' : (#29323c, #485563, 0deg, 0%, 100%),
	'nega' : (#ee9ca7, #ffdde1, 0deg, 0%, 100%),
	'night' : (#1e3c72, #2a5298, 0deg, 0%, 100%),
	'gentle' : (#ffc3a0, #ffafbd, 0deg, 0%, 100%),
	'salad' : (#b7f8db, #50a7c2, 0deg, 0%, 100%),
	'a' : (#d9afd9, #97d9e1, 0deg, 0%, 100%),
	'b' : (#74ebd5, #9face6, 0deg, 0%, 100%),
	'c' : (#8ec5fc, #e0c3fc, 0deg, 0%, 100%),
	'd' : (#21d4fd, #b721ff, 0deg, 0%, 100%),
	'e' : (#08aeea, #2af598, 0deg, 0%, 100%),
	'f' : (#d9afd9, #97d9e1, 0deg, 0%, 100%),
	'h' : (#08aeea, #2af598, 0deg, 0%, 100%),
	'g' : (#74ebd5, #9face6, 90deg, 0%, 100%),
	'i' : (#21d4fd, #b721ff, 19deg, 0%, 100%),
	'j' : (#8ec5fc, #e0c3fc, 62deg, 0%, 100%),
	'k' : (#faaca8, #ddd6f3, 19deg, 0%, 100%),
	'l' : (#fbab7e, #f7ce68, 62deg, 0%, 100%),
	'm' : (#a9c9ff, #ffbbec, 180deg, 0%, 100%),
	'n' : (#90f7ec, #32ccbc, 135deg, 10%, 100%),
	'o' : (#52e5e7, #130cb7, 135deg, 10%, 100%),
	'p' : (#79f1a4, #0e5cad, 135deg, 10%, 100%),
	'q' : (#97abff, #123597, 135deg, 10%, 100%),
	'r' : (#6b73ff, #000dff, 135deg, 10%, 100%),
	's' : (#72edf2, #5151e5, 135deg, 10%, 100%),
	't' : (#3c8ce7, #00eaff, 135deg, 10%, 100%),
	'u' : (#90f7ec, #32ccbc, 135deg, 10%, 100%)
);

@each $name, $grad in $gradients {
	$c1: nth($grad, 1);
	$c2: nth($grad, 2);
	// $deg: nth($grad,3);

	%grad {
		&-#{$name} {
			// @include gradient($c1,$c2,$deg)
			@include gradient($grad...);
		}
	}
}

%clear-to-black {
	background: rgb(0, 0, 0);
	background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
	background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
	// background: rgb(0,0,0);
	// background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 67%, rgba(0,0,0,1) 100%);
	// background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 67%, rgba(0,0,0,1) 100%);
	// background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 67%, rgba(0,0,0,1) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}
