table {
	font-size: .9rem;

	tr {
		
	}
	
	td {
		padding: 0 0.5rem;
		line-height: 2;
		text-align: left;
		
		&:first-child {
			font-weight: $bold;
			text-align: right;

		}
	}

}