@import "../config/variables";

.headline {
	font-size: 2.25rem;
	font-weight: 600;
	// color: $fg-primary;
	line-height: 1.5;
}

.subheadline {
	font-size: 1.25rem;
	font-weight: 400;
	// color: $fg-secondary;
}

.eyebrow {
	font-size: .9rem;
	font-weight: 700;
	// color: $fg-secondary;
	text-transform: uppercase;
	letter-spacing: 0px;
}

.detail {
	
}