// @import 'config/_config';
@import '../../../config/variables';


$theme-primary: $blue;
$theme-secondary: $teal;
$theme-success: $green;
$theme-danger: $red;
$theme-info: $purple;
$theme-warning: $orange;
$theme-disabled: $gray;
$theme-black: #000000;
// $theme-dark: #343434;
// $theme-dark: $asphalt;
$theme-dark: $wet-asphalt;
$theme-light: $offwhite;
$theme-white: #ffffff;
$theme-transparent: transparent;
$theme-limo-tint:   rgba(0,0,0,0.87);
$theme-dark-tint: 	rgba(0,0,0,0.5);
$theme-tint: 			  rgba(0,0,0,0.38);
$theme-light-tint: 	rgba(0,0,0,0.12);
$theme-gunmetal: 				$gunmetal;
$theme-dark-gunmetal: 	$dark-gunmetal;

$schemeConfig: 
	'primary'       $theme-primary, 
	'secondary'     $theme-secondary, 
	'success'       $theme-success,
	'danger'        $theme-danger,
	'info'          $theme-info,
	'warning'       $theme-warning,
	'disabled'      $theme-disabled,
	'black'         $theme-black,
	'dark'          $theme-dark,
	'light'         $theme-light,
	'white'         $theme-white,
	'transparent'   $theme-transparent,
	'red'           $red,
	'orange'        $orange,
	'yellow'        $yellow,
	'green'         $green,
	'teal'          $teal,
	'blue'          $blue,
	'purple'        $purple,
	'dark-bluegrey' $dark-blue-grey,
	'limo-tint' 		$theme-limo-tint,
	'dark-tint' 		$theme-dark-tint,
	'tint' 		      $theme-tint,
	'light-tint'	  $theme-light-tint,
	'gunmetal'	    $theme-gunmetal,
	'dark-gunmetal'	$theme-dark-gunmetal,
	'code-red'	        $code-red,
	'code-orange'	      $code-orange,
	'code-yellow'	      $code-yellow,
	'code-green'	      $code-green,
	'code-cyan'	        $code-cyan,
	'code-blue'	        $code-blue,
	'code-purple'	      $code-purple,
	'code-highlighted'	$code-highlighted,
	'code-mono-1'	      $code-mono-1,
	'code-mono-2'	      $code-mono-2,
	'code-mono-3'	      $code-mono-3,
	'code-bg'	          $code-bg
;

@each $i in $schemeConfig {
	$key: nth($i,1);
	$color: nth($i,2);

	$is-dark:  ( lightness($color) < 5  );
	$is-light: ( lightness($color) > 80 );

	$text-color: if( $is-light, $text-primary, #fff);
	$hover-color: if( $is-dark, lighten($color,5), darken($color,2) );

	.ws-#{$key} {
		background-color: $color !important;
		color: $text-color !important;
		transition: background-color 400ms;
		.ws-btn, .btn {
			&:hover, &:active, &:focus {
				background-color: $hover-color !important;
				color: $text-color !important;
			}
		}

		&.isOutlined {
			background-color: transparent;
			color: $color;
			border-color: $color;
			.ws-btn, .btn, a {
				&:hover, &:active, &:focus {
					// color: $hover-color;
					// border-color: $hover-color;
					background-color: $color;
					color: $text-color;
				}
			}
		}
	}

	.bg-#{$key} {
		background-color: $color !important;
		.ws-btn, .btn, a {
			&:hover, &:active, &:focus { 
				background-color: $hover-color !important;
			}
		}
	}
	.fg-#{$key} {
		color: $color !important;
		.ws-btn, .btn, a {
			&:hover, &:active, &:focus { 
				color: $hover-color !important;
			}
		}
	}
	
}

.isOutlined {
	border-style: solid;
	border-width: 2px;
}