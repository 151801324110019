@import '../../config/variables';

$resume-max-w: 800px;

.theme-dark {
	.control-bar{
		background-color: $asphalt;
		.ws-btn {
			color: $fg-light-primary;
			color: $fg-light-secondary;
			border-color: $fg-light-divider;
			transition: all .24s;
			&:hover,&:focus {
				color: $fg-light-primary;
				border-color: $fg-light-secondary;
				
				background-color: #fff !important;
				color: $fg-secondary;
				
				// background-color: rgba(#000,0.12) !important;
			}
		}
	}
}
.resume-page {
	background-color: #fff;
	main {
		padding-bottom: 6rem;
	}
}
// ===== Container ===== //
.resume-container {
	padding: 4rem 1rem;
	max-width: $resume-max-w;
	margin: auto;
	font-size: 1.1rem;
	* {
		font-size: inherit;
	}
}
// ===== Header ===== //
.resume-header {
	// outline: 1px solid red;
	font-size: 1rem;
	padding: 0;
	margin-bottom: 3rem;

	> * {
		// outline: 1px solid yellow;
	}

	.headline {
		text-align: center;
		font-weight: 700;
		font-size: 2.4rem;
		margin: 0.5rem auto;
		line-height: 1;
	}

	p,
	a,
	li {
		font-size: inherit;
		line-height: 1;
	}
}
// ===== Control Bar ===== //
.control-bar {
	// padding: 1rem;
	background-color: rgba(#000, 0.04);
	// box-shadow: 0 5px 7px 0 rgba(36,50,66,0.2);
	border-bottom: 1px solid rgba(#000, 0.08);

	.control-bar-content {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: .5rem;
		max-width: $resume-max-w;
		margin: auto;

		> * {
			margin: 0 0.5rem;
		}
	}
	.ws-btn {
		$btnColor: rgba(#000, 0.66);
		border: 2px solid $btnColor;
		color: $btnColor;
		background-color: transparent;
		padding: 0rem 1rem;
		font-size: 1.1rem;
		line-height: 2.5em;
	}
}
// ===== Content ===== //
.section-container {
	margin-bottom: 2rem;
}
.section-label {
	font-size: 1.2rem;
	color: #000;
	opacity: 1;
	margin-bottom: 1rem;
}
.resume-section {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	// margin: 2rem 0;
	margin: 0;
	margin-bottom: 2rem;
	padding: 0 0rem;
	position: relative;
	> * {
		font-size: 1.15rem;
		line-height: 1;
		margin: 0;
		margin-bottom: .5rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.section-title {
		font-size: 1.5rem;
		font-weight: 700;
		color: rgba(#000, 0.87);
	}
	.job-title {
		font-style: italic;
	}
	.section-subhead {
		font-style: italic;
		color: rgba(#000, 0.5);
	}

	.headline {
	}
	ul {
		list-style-type: disc;
		padding-left: 1.5em;
		li {
			display: list-item;
			line-height: 1.5;
		}
	}
}
.project-link.ws-btn,
.project-link {
	font-weight: 300;
	font-size: 1.1rem;
	padding: 0 .75rem;
	margin: 0 4px;
	display: inline-block;
	background-color: #ecf0f1;
	color: rgba(#000, 0.87);
}

// ===== View States ===== //
.view-links {
	.section-container {
		// max-width: 500px;
		max-width: 340px;
		margin: 0 auto;
	}
	.hide-on-links-only {
		display: none;
	}
.resume-header {
	margin-bottom: 5rem;
}
	.resume-section {
		flex-direction: row;
		align-items: center;
		// justify-content: flex-end;
		justify-content: flex-start;
		padding: 0;

		> * {
			margin-left: 1rem;
			display: none;
		}
		@media all and (max-width: 400px) {
			flex-direction: column;
			align-items: center;
			> * {
				margin-left: 0;
			}
		}
		:first-child {
			margin-left: 0;
		}

		.section-title {
			width: 100%;
			max-width: 100px;
			text-align: right;
			margin-bottom: 0;
		}
		.section-title,
		.project-link-group {
			display: inline-block;
		}
	}
	.section-label {
		text-align: center;
	}
}

.resume-date {
	position: absolute;
	top: 0;
	right: 0;
}
