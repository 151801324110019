@import '../../config/variables';

$card-padding: 2.4rem;
$base-z: $zidx-mid;
.ws-card {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	margin: 1rem 0;
	padding: 0;
	position: relative;
	overflow: hidden; // hides corners of action bar
	@include border-radius(1rem);
	font-size: 1rem;
	// background-color: $dark-gunmetal;
	background-color: $gunmetal;
	@media (max-width: 500px) {
		font-size: 0.9rem;
	}
	@media (max-width: 400px) {
		font-size: 0.8rem;
	}

	&:hover {
		// background-color: unset;
	}
	&.barren {
		justify-content: flex-end;
	}
	.content {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		font-size: 1.4em;
		padding-top: 3rem;
		padding-bottom: 1rem;
		font-weight: 300;
		text-align: left;
		color: #fff;
		letter-spacing: 0.5px;
		// margin-bottom: .5em;
		position: relative;
		background-color: inherit;

		@include border-radius(inherit);
		z-index: $base-z + 10;

		.card-title-wrap {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			> * {
				flex: 1 1 initial;
			}
		}
		.card-title {
			line-height: 1;
			font-size: 1.5em;
			// margin-bottom: 0.4em;
			font-weight: 600;
		}
		.card-subtitle {
			line-height: 1.4;
			font-size: 1.05em;
			font-weight: 300;
			color: $light-secondary;
			color: rgba(#fff, 0.8);
			letter-spacing: 0px;
			word-spacing: 0.4px;
		}
	}
	// Actions ==============================
	.actions {
		// flex-basis: 58px;
		font-size: 1em;
		line-height: 1;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		// justify-content: flex-end;
		align-items: center;
		background-color: inherit;
		color: #fff;
		// padding: 1rem 2rem;
		$p: 1.5rem;
		padding: $card-padding;
		padding-top: 1rem;
		// padding-bottom: $p;
		position: relative;
		z-index: $base-z - 12;

		a {
			display: inline-block;
			line-height: 2em;
			margin-left: 1rem;
			background-color: $disabled;
			background-color: rgba(0, 0, 0, 0.12);
			padding: 0 1em;
			// border-radius: 0.4em;
			@include border-radius(5px);
			// font-size: 1rem;
			text-transform: none;
			color: $light-secondary;
			font-weight: 300;

			&:hover,&:focus {
				text-transform: none;
			}

			&.alpha {
				// background-color: #fff;
				// color: $primary;
				background-color: rgba(0, 0, 0, 0.24);
				color: $fg-light-primary;
				font-weight: 400;
			}
			&.beta {
				background-color: transparent;
				color: $fg-light-primary;
				// color: $light-secondary;
				padding: 0;
				font-weight: 300;
			}
			&.under-maintenance {
				background-color: rgba(0, 0, 0, 0.12);
				color: $fg-light-disabled;
				// padding: 0;
				// font-weight: 300;
			}
			img {
				display: inline-block;
				height: 1.2em;
				line-height: inherit;
			}
		}
	}
	// .mouth,
	// .content, .actions
	& > * {
		padding-left: $card-padding;
		padding-right: $card-padding;
	}

	.action-btns {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	.ws-btn {
		font-size: 1rem;
		font-weight: 300;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		background-color: rgba(0, 0, 0, 0.2);
		color: $light-primary;
		padding: 0.5em 0.8em;
	}
}

// .ws-card .actions ,
.card-action,
.action-bar {
	a {
		text-transform: uppercase;
		font-weight: 400;
		letter-spacing: 0.3px;
		margin-right: 0 !important;
		color: #fff !important;
	}
}

.card-wrap {
	text-align: left;
	font-size: 1rem;
	padding: 3rem 0;
}

.ws-mouth {
	background-color: inherit;
	z-index: $base-z - 10;
	position: relative;
	$p: 0rem;
	padding-top: $p;
	padding-bottom: $p;
	.mouth-tint {
		background-color: rgba(0, 0, 0, 0.12);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: $base-z -9;
	}

	.mouth {
		background-color: inherit;
		color: #fff;
		// padding: $card-padding;
		height: 0;
		overflow: hidden;
		// padding: 0 $card-padding;
		padding: 0 0.5rem;
		transition-delay: height 0.4s;
	}

	.tonsils {
		// background-color: rgba(0,0,0,0.3);
		background-color: transparent;
		color: #fff;
		padding: 0 $card-padding;
		// position: absolute;
		position: relative;
		margin-bottom: 1rem;
		// bottom: -3rem;
		text-align: center;
		// left: 50%;
		// transform: translateX(-50%);
		// left: 0%;
		cursor: pointer;
		z-index: $base-z + 11;

		span {
			position: relative;
			z-index: inherit;
			font-size: 2rem;
			transition: transform 400ms;
			display: inline-block;
		}
	}
}

.opened-true {
	.tonsils {
	}
	.dotdotdot {
		// visibility: hidden;
		opacity: 0;
		span {
			// transform: rotate(180deg);
		}
	}
	.mouth {
		height: initial;
		overflow: visible;
		padding: 2.4rem;
		padding-top: 1rem;
	}
	.ReactCollapse--collapse {
		padding: 0;
	}
}
